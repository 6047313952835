import { getCatalogueDetails } from "@/services/catalogueServices";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCatalogDetails = createAsyncThunk(
  "catalog/getCatalogueDetails",
  async (params) => {
    try {
      const response = await getCatalogueDetails(params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const reFetchCatalogDetails = createAsyncThunk(
  "catalog/reFetchCatalogDetails",
  async (params) => {
    try {
      const response = await getCatalogueDetails(params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
