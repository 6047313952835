"use client";

import { store } from "./store";
import { Provider } from "react-redux";
import Spinner from "@/components/common/Loader/Spinner";

export const StoreProvider = ({ children }) => {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate> */}
      {children}
    </Provider>
  );
};
