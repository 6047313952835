"use client";

import { useEffect } from "react";

const ChatWidget = () => {
  const config = {
    widgetToken: "b9ff9",
    hide_launcher: false, // or true
    show_widget_form: true, // or false
    show_close_button: true, // or false
    launch_widget: false, // or false
    show_send_button: true, // or false
    unique_id: "", // Replace with actual unique ID
    name: "", // Optional
    number: "", // Optional
    mail: "", // Optional
    country: "India", // Optional
    city: "", // Optional
    region: "", // Optional
  };
  useEffect(() => {
    // Load the script
    const script = document.createElement("script");
    script.src = "https://control.msg91.com/app/assets/widget/chat-widget.js";
    script.async = true;
    script.onload = () => initChatWidget(config, 5000);
    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, [config]);

  return null; // This component doesn't render anything
};

export default ChatWidget;
