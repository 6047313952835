// wishlistSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    incrementWishlistCount(state) {
      state.count += 1;
    },
    decrementWishlistCount(state) {
      if (state.count > 0) {
        state.count -= 1;
      }
    },
    setWishlistCount(state, action) {
      state.count = action.payload;
    },
  },
});

export const {
  incrementWishlistCount,
  decrementWishlistCount,
  setWishlistCount,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
