import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Poppins-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Poppins-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Poppins-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Poppins-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-family-primary\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/ChatWidget.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/Toast/ToastComponent.js");
;
import(/* webpackMode: "eager", webpackExports: ["AllProviders"] */ "/app/src/providers/AllProviders.js");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/common.scss");
