"use client";
import React from "react";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import "./toast.scss";
import { X } from "react-feather";

const ToastComponent = () => {
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={true}
      gutter={8}
      containerClassName="toast__container"
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "toast__bar",
        duration: 5000,
        style: {
          background: "#000",
          color: "#fff",
          maxWidth: 750,
        },

        // Default options for specific types
        success: {
          duration: 5000,
          theme: {
            primary: "green",
            secondary: "black",
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t} position="bottom-center">
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <button
                  className="toast__close-btn"
                  onClick={() => toast.dismiss(t.id)}
                >
                  <X />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToastComponent;
