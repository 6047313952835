"use client";

import React from "react";
import { StoreProvider } from "@/store/StoreProvider";
import AntdProvider from "./AntdProvider";

const CombineProviders = (providers) =>
  providers.reduce(
    (AccumulatedProviders, [Provider, props = {}]) =>
      ({ children }) =>
        (
          <AccumulatedProviders>
            <Provider {...props}>
              <>{children}</>
            </Provider>
          </AccumulatedProviders>
        ),
    ({ children }) => <>{children}</>
  );

export const AllProviders = CombineProviders([
  [StoreProvider],
  [AntdProvider],
  // [MuiThemeProvider, { theme }],
  // [MuiPickersUtilsProvider, { utils: DateFnsUtils }],
  // [StylesProvider, { injectFirst: true }],
  // [Provider, { store: configureStore() }],
  // [Provider1],
  // [Provider2],
  // [MyComponent, {name: 'John']
]);

//source: https://egor-xyz.medium.com/how-to-combine-providers-in-react-e553a155d0b9
