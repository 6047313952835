"use client";

// hooks/useInvestoreDetails.js
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as PYRServices from "@/services/PYRServices";
import { setInvestorData } from "@/store/slices/authInvestoreData";
import useAuth from "@/hooks/useAuth";

const useInvestoreDetails = () => {
  const dispatch = useDispatch();
  const { profile, user } = useAuth();
  // const user = useSelector((state) => state?.userAuth?.auth?.user);
  // const profile = useSelector((state) => state?.userAuth?.profile);
  // const user = auth?.user || {};
  const handleFindInvestor = async (phoneNumber) => {
    try {
      const payload = {
        search: phoneNumber || undefined,
      };
      const response = await PYRServices.findInvestor(payload);
      dispatch(setInvestorData(response?.data));
    } catch (error) {}
  };

  useEffect(() => {
    if (user?.mobile) {
      handleFindInvestor(profile?.mobile || user?.mobile);
    }
  }, [user?.mobile]);

  return {};
};

export default useInvestoreDetails;
//authThunks
