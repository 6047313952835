"use client";

import React from "react";
import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
// import { Poppins } from "next/font/google";
import localFont from "next/font/local";
import useInvestoreDetails from "@/hooks/useInvestoreDetails";
// const poppins = Poppins({
//   weight: ["100", "200", "300", "400", "500", "600", "700"],
//   style: ["normal", "italic"],
//   subsets: ["latin"],
//   display: "swap",
// });

const poppins = localFont({
  src: [
    {
      path: "../assets/fonts/Poppins-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../assets/fonts/Poppins-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../assets/fonts/Poppins-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../assets/fonts/Poppins-Bold.ttf",
      weight: "700",
      style: "normal",
    },
  ],
  variable: "--font-family-primary",
});
const colorPrimary = "rgb(37, 127, 217)";

const AntdProvider = ({ children }) => {
  useInvestoreDetails()
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPrimary,
          colorBgSpotlight: colorPrimary,
          //fontFamily: "Poppins, sans-serif",
          //fontFamily: var('--p-font-family-sans'),
          fontFamily: poppins?.style?.fontFamily,
        },
      }}
    >
      <StyleProvider hashPriority="high">{children}</StyleProvider>
    </ConfigProvider>
  );
};

export default AntdProvider;
