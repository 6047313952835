import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCatalogDetails,
  reFetchCatalogDetails,
} from "../thunks/catalogDetailsThunk";

const initialState = {
  details: null,
  fetching: false,
  isFetched: false,
  reFetching: false,
};

export const catalogDetailsSlice = createSlice({
  name: "catalogDetails",
  initialState: initialState,
  reducers: {
    setCountriesAction: (state, action) => {
      //state.countries = action?.payload || [];
    },
    resetCatalogDetailsAction: (state) => {
      state.details = null;
      state.fetching = false;
      state.isFetched = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogDetails.pending, (state) => {
        state.fetching = true;
        state.error = null;
        state.details = null;
        state.isFetched = false;
      })
      .addCase(fetchCatalogDetails.fulfilled, (state, action) => {
        state.fetching = false;
        state.isFetched = true;
        state.details = action.payload;
      })
      .addCase(fetchCatalogDetails.rejected, (state, action) => {
        state.fetching = false;
        state.isFetched = true;
        state.error = action.error.message;
        state.details = null;
      })

      // Handle re-fetching separately
      .addCase(reFetchCatalogDetails.pending, (state) => {
        state.reFetching = true;
      })
      .addCase(reFetchCatalogDetails.fulfilled, (state, action) => {
        state.reFetching = false;
        state.details = action.payload;
      })
      .addCase(reFetchCatalogDetails.rejected, (state, action) => {
        state.reFetching = false;
        state.error = action.error.message;
      });
  },
});

// Action creators
export const { resetCatalogDetailsAction } = catalogDetailsSlice.actions;

export default catalogDetailsSlice.reducer;
