"use client";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import miscReducer from "./slices/miscSlice";
import authModalSliceReducer from "./slices/authModalSlice";
import wishlistReducer from "./slices/wishlistSlice";
import metaDataReducer from "./slices/metaDataSlice";
import compareReducer from "./slices/compareSlice";
import authInvestoreDataReducer from "./slices/authInvestoreData";
import catalogDetailsSliceReducer from "./slices/catalogDetailsSlice";
import headerSearchReducer from "./slices/headerSearchSlice";
import { loadState, saveState } from "@/utils/authHelper";
import { profileReducer } from "./slices/profileSlice";

const rootReducer = combineReducers({
  misc: miscReducer,
  authModal: authModalSliceReducer,
  wishlist: wishlistReducer,
  metaDataDetials: metaDataReducer,
  compare: compareReducer,
  investorDetails: authInvestoreDataReducer,
  catalogDetails: catalogDetailsSliceReducer,
  headerSearch: headerSearchReducer,
  authState: profileReducer,
});

const persistedState = loadState();
// console.log("NODE_ENV", process.env.NODE_ENV);
// console.log("NEXT_PUBLIC_APP_ENV", process.env.NEXT_PUBLIC_APP_ENV);

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  devTools: process.env.NODE_ENV !== "production",
  // redux-thunk is included by default, so no need to add it manually
});

store.subscribe(() => {
  saveState(store.getState());
});

//console.log("getStore", store.getState());
