// metaDataSlice.js

import { isProductionEnv } from "@/utils/helper";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meta_title: "",
  meta_description: "",
  meta_keywords: "",
  robots: isProductionEnv ? "index, follow" : "noindex, nofollow",
  meta_abstract: `Franchise Discovery: Your one-stop shop for education franchise opportunities in India. Browse our top education franchises today and find the perfect fit for you! above sentences will be fixed for every page only you can replace "blue words" by Industries/ category/ subcategory/ Brand Name etc.`,
  meta_topic: "",
  meta_url: "",
  google_plus_markup_name: "",
  google_plus_markup_description: "",
  google_plus_markup_image: "",
  og_title: "",
  og_type: "Website",
  og_URL: "",
  og_image: "",
  og_description: "",
  og_site_name: "Franchise Discovery",
};

const metaDataSlice = createSlice({
  name: "metaDataDetials",
  initialState,
  reducers: {
    updateMetaDataForComponent(state, action) {
      console.log("statessssss", state, action.payload);
      return { ...state, ...action.payload };
    },
  },
});

export const { updateMetaDataForComponent } = metaDataSlice.actions;

export default metaDataSlice.reducer;
